



























































































































































import {
    computed,
    defineComponent,
    reactive,
    toRefs,
    watch,
} from "@vue/composition-api"
import SpaceObjectLocation from "@/components/SpaceObjectLocation.vue"
import SpaceObjectMileage from "@/views/SpaceObject/SpaceObjectMileage.vue"
import TimeText from "@/components/TimeText.vue"
import KeywordInput from "@/components/KeywordInput.vue"
import DateTimeRangeInput from "@/components/DateTimeRangeInput.vue"
import PlacePagingSelect from "@/components/PlacePagingSelect.vue"
import UpdateIssueTrackingTableDialog from "./UpdateIssueTrackingTableDialog.vue"
import {
    page,
    itemsPerPage,
    keyword,
    buildNumberArrayFilter,
    buildStringFilter,
    buildNumberFilter,
    buildTimeRangeFilter,
} from "@/services/QueryParmsService"
import { currentRoute } from "@/services/Router"
import { timeFormatter } from "@/utilities/Formatter"
import {
    IssueViewModel,
    getIssues,
    issueStateFormatter,
    IssueState,
    IssueSortMethod,
} from "@/services/IssueService"
import { SpaceObjectCache } from "@/services/SpaceObjectsService"
import { SelectOption } from "@/plugins/vuetify"

export default defineComponent({
    name: "Issue",
    components: {
        TimeText,
        KeywordInput,
        SpaceObjectLocation,
        SpaceObjectMileage,
        PlacePagingSelect,
        UpdateIssueTrackingTableDialog,
        DateTimeRangeInput,
    },
    setup() {
        const stateFilter = buildNumberArrayFilter("state", [
            IssueState.Open,
            IssueState.Repairing,
            IssueState.ReportRepaired,
            IssueState.CheckingRepaired,
            IssueState.ReportChecked,
        ])
        const locationIdFilter = buildStringFilter("locationId")
        const createdTimeFilter = buildTimeRangeFilter("createdTime")
        const sortMethod = buildNumberFilter(
            "s",
            IssueSortMethod.CreatedTimeDesc
        )
        const state = reactive({
            items: [] as IssueViewModel[],
            total: 0,
            loading: false,
            showUpdateTrackingTableDialog: false,
        })

        const totalPages = computed(() =>
            Math.ceil(state.total / itemsPerPage.value)
        )

        async function loadData() {
            state.loading = true
            const { total, items } = await getIssues({
                skip: itemsPerPage.value * (page.value - 1),
                take: itemsPerPage.value,
                keyword: keyword.value,
                states: stateFilter.value,
                locationSpaceObjectIds: locationIdFilter.value
                    ? [locationIdFilter.value]
                    : undefined,
                sort: sortMethod.value,
                createdTimeStart: createdTimeFilter.value.start,
                createdTimeEnd: createdTimeFilter.value.end,
            })
            state.items = items.map((item) => {
                const result: Partial<IssueViewModel> = item
                result.spaceObject = undefined
                return result as IssueViewModel
            }) as IssueViewModel[]

            state.total = total

            await SpaceObjectCache.updateByIds(
                items
                    .map((i) => i.spaceObjectId)
                    .filter((id) => !!id) as string[]
            )

            state.items.forEach((item) => {
                if (!item.spaceObjectId) return
                item.spaceObject = SpaceObjectCache.get(item.spaceObjectId)
            })
            state.loading = false
        }

        watch(
            () => currentRoute.value!.query,
            () => {
                loadData()
            }
        )

        loadData()

        function clickLocation(id: string) {
            locationIdFilter.value = id
        }

        const headers = [
            {
                text: "ID",
            },
            {
                text: "問題物件",
            },
            {
                text: "問題物件里程",
            },
            {
                text: "問題物件分類",
            },
            {
                text: "主旨",
            },
            {
                text: "建立時間",
            },
            {
                text: "狀態",
            },
            {
                text: "更新時間",
            },
            {
                text: "動作",
            },
        ]

        const stateOptions: SelectOption[] = [
            IssueState.Open,
            IssueState.Repairing,
            IssueState.ReportRepaired,
            IssueState.CheckingRepaired,
            IssueState.ReportChecked,
            IssueState.Closed,
        ].map((s) => ({
            text: issueStateFormatter(s),
            value: s,
        }))

        const sortOptions = [
            {
                text: "建立時間早到晚",
                value: IssueSortMethod.CreatedTime,
            },
            {
                text: "建立時間晚到早",
                value: IssueSortMethod.CreatedTimeDesc,
            },
            {
                text: "所在位置",
                value: IssueSortMethod.Location,
            },
        ]

        return {
            ...toRefs(state),
            headers,
            page,
            itemsPerPage,
            totalPages,
            loadData,
            timeFormatter,
            issueStateFormatter,
            stateOptions,
            stateFilter,
            createdTimeFilter,
            locationIdFilter,
            clickLocation,
            sortOptions,
            sortMethod,
            keyword,
        }
    },
})
