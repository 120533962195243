





























import { defineComponent, reactive, toRefs } from "@vue/composition-api"
import { updateIssueTrackingTableFile } from "@/services/IssueService"
import saveAs from "file-saver"
import {
    closeDialog,
    DialogButtonType,
    DialogLevel,
    showDialog,
} from "@/services/DialogService"

export default defineComponent({
    name: "UpdateIssueTrackingTableDialog",
    props: {
        showDialog: {
            type: Boolean,
            default: false,
        },
        locationIdFilter: String,
        stateFilter: {
            type: Array as () => number[],
        },
        keyword: {
            type: String,
        },
    },
    setup(props, { emit }) {
        const state = reactive({
            file: null as File | null,
        })

        function close() {
            emit("close")
        }

        async function submit() {
            if (!state.file) {
                return
            }
            showDialog(
                "管制表更新中",
                "請稍後",
                DialogLevel.Info,
                DialogButtonType.None
            )

            const response = await updateIssueTrackingTableFile(state.file, {
                keyword: props.keyword,
                states: props.stateFilter,
                locationSpaceObjectIds: props.locationIdFilter
                    ? [props.locationIdFilter]
                    : undefined,
            })

            const blob = await response.blob()
            saveAs(blob, state.file.name)
            closeDialog()
        }

        return {
            ...toRefs(state),
            close,
            submit,
        }
    },
})
